import { Checkbox } from '@chakra-ui/react';
import React from 'react';
import { responsive } from '../contexts/responsive';

const ReCheckbox = (props) => (
  <Checkbox
    width="100%"
    mt="0.75rem"
    py={responsive('0.375rem', '0.7em')}
    border={responsive('1.5px solid', '3px solid')}
    borderColor="black"
    fontWeight={500}
    borderRadius={'1.5rem'}
    _checked={{
      bg: 'black',
      color: 'white',
    }}
    {...props}
  />
)

export default ReCheckbox;
